
import request from '@/utils/request'
import qs from 'qs'

export function problemByType (page,limit,problemType) {
  let data = {
    page,limit,problemType
  }
  data = qs.stringify(data);
  return request({
    url: '/problems/problemByType',
    method: 'post',
    data
  })
}

export function listCounseling (page,limit) {
  let data = {
    page,limit
  }
  data = qs.stringify(data);
  return request({
    url: '/counseling/listCounseling',
    method: 'post',
    data
  })
}

