<template>
  <div class="content">
    <div class="toppicd">
      <img src="../../../Newimg/marketing.jpg" alt="" />
    </div>
    <!-- <div class="header">论文投发平台</div> -->
    <div class="page-body">
      <div class="card1">
        <div class="item" v-for="(item, index) in card1data" :key="index">
          <img class="icon" :src="item.icon" alt="" />
          <div class="data">
            <div class="title">{{ item.title }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
      </div>
      <div class="card2">
        <div class="title">为什么我们要发表文章？</div>
        <div class="subtitle">告别晋升痛点，发表帮你解决问题</div>
        <div class="articlemarketinglist">
          <div class="item" v-for="(item, index) in articlemarketinglist" :key="index">
            <img class="icon" :src="item.icon" alt="" />
            <div class="name">{{ item.name }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
        <div class="button" @click="kefu">免费咨询</div>
      </div>
    </div>
    <div class="card3">
      <div class="title">4大期刊类别，加急见刊</div>
      <div class="subtitle">覆盖面广，满足多样需求</div>
      <div class="articlemarketinglist">
        <div class="item" v-for="(item, index) in catemarketinglist" :key="index">
          <img class="icon" :src="item.icon" alt="" />
          <div class="name">{{ item.name }}</div>
          <div class="content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="card2">
        <div class="title">简化流程，快速服务</div>
        <div class="subtitle">简化流程，快速服务</div>
        <div class="catemarketinglist">
          <div class="item" v-for="(item, index) in flowmarketinglist" :key="index">
            <div>
              <img class="icon" :src="item.icon" alt="" />
              <div class="name">{{ item.name }}</div>
              <div class="content">{{ item.content }}</div>
            </div>
            <div v-if="index < flowmarketinglist.length - 1" class="line"></div>
          </div>
        </div>
        <div class="button" @click="kefu">免费咨询</div>
        <div class="title" style="margin-top: 60px">我们的优势</div>
        <div class="subtitle">
          帮助您论文成功率提高96%，发表时间率缩短90%，避免上当受骗！
        </div>
      </div>
    </div>
    <div class="card4">
      <div class="left" style="text-align:right;">
        <div class="item">
          <div class="title">刊大师√</div>
          <div class="marketinglist" style="display:flex;flex-direction: column;align-items: flex-end;">
            <div
              class="marketinglist-item"
              v-for="(item, index) in leftmarketinglist"
              :key="index"
            >
              <div>
                <div class="name">{{ item.name }}</div>
                <div class="content">{{ item.content }}</div>
              </div>
              <img class="icon" :src="item.icon" alt="" style="margin-right:0;margin-left:16px" />
            </div>
          </div>
        </div>
      </div>
      <div class="vs">
        <img :src="vsicon" />
        <img :src="vsicon" />
        <img :src="vsicon" />
      </div>
      <div class="right">
        <div class="item">
          <div class="title">其他网站×</div>
          <div class="marketinglist">
            <div
              class="marketinglist-item"
              v-for="(item, index) in rightmarketinglist"
              :key="index"
            >
              <img class="icon" :src="item.icon" alt="" />
              <div>
                <div class="name">{{ item.name }}</div>
                <div class="content">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-body">
      <div class="card2">
        <div class="nicemarketinglist">
          <div class="item" v-for="(item, index) in nicemarketinglist" :key="index">
            <div class="index">
              <img class="icon" :src="item.icon" alt="" />
              <div class="num">{{ item.id }}</div>
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="content">{{ item.content }}</div>
          </div>
          <div v-if="index < flowmarketinglist.length - 1" class="line"></div>
        </div>
        <div class="button" @click="kefu">免费咨询</div>
        <div class="title" style="margin-top: 80px">4大服务保障 • 确保无忧</div>
        <div class="ptyscontents2">
          <ul>
            <li v-for="(item, index) in imgsmarketinglist" :key="index" style="justify-content:flex-end;">
              <div class="icon">
                <img :src="item.icon" alt="" />
              </div>
              <div class="ptyscontents2-item">
                <p class="name">{{item.name}}</p>
                <p class="conent">{{ item.content }}</p>
              </div>
            </li>
          </ul>
          <div class="baozhang2">
            <img src="../../assets/image/marketing/marketingLogo.jpg" alt="" />
          </div>
          <ul>
            <li v-for="(item, index) in imgsmarketinglists" :key="index">
              <div class="icon">
                <img :src="item.icon" alt="" />
              </div>
              <div class="ptyscontents2-item">
                <p class="name">{{item.name}}</p>
                <p class="conent">{{ item.content }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mytitle from "@/components/title.vue";
import { problemByType } from "../../API/homepage/problem.js";
export default {
  components: {
    mytitle,
  },
  data() {
    return {
      now: 0,
      vsicon: require("../../assets/image/marketing/marketingVS.png"),
      imgsmarketinglist:[
        {
          name: "资质保障",
          content: "与1000+杂志社长期稳定合作渠道优势保障见刊速度",
          icon: require("../../assets/image/marketing/marketing14.jpg"),
        },
        {
          name: "见刊快",
          content: "与1000+杂志社长期稳定合作渠道优势保障见刊速度",
          icon: require("../../assets/image/marketing/marketing15.jpg"),
        },
      ],
      imgsmarketinglists:[
        {
          name: "见刊快",
          content: "与1000+杂志社长期稳定合作渠道优势保障见刊速度",
          icon: require("../../assets/image/marketing/marketing12.jpg"),
        },
        {
          name: "见刊快",
          content: "与1000+杂志社长期稳定合作渠道优势保障见刊速度",
          icon: require("../../assets/image/marketing/marketing13.jpg"),
        },
      ],
      nicemarketinglist: [
        {
          id: "01",
          name: "见刊快",
          content: "与1000+杂志社长期稳定合作渠道优势保障见刊速度",
          icon: require("../../assets/image/marketing/marketingBG.png"),
        },
        {
          id: "02",
          name: "稳定录用",
          content: "从事期刊服务10年，操作经验丰富，录用有保障",
          icon: require("../../assets/image/marketing/marketingBG.png"),
        },
        {
          id: "03",
          name: "服务成功再收费",
          content: "完善的用户保障体系，全面保护消费者利益",
          icon: require("../../assets/image/marketing/marketingBG.png"),
        },
        {
          id: "04",
          name: "实体公司合规经营",
          content: "资质证件齐全，对公企业账号，工商部门可查",
          icon: require("../../assets/image/marketing/marketingBG.png"),
        },
      ],
      leftmarketinglist: [
        {
          name: "正规运营",
          content: "所有运营资质正规可查，办公地址详实，专业保障",
          icon: require("../../assets/image/marketing/marketingdui.png"),
        },
        {
          name: "资金监管",
          content: "支付走对公账户，并受第三方银行监管，安全放心",
          icon: require("../../assets/image/marketing/marketingdui.png"),
        },
        {
          name: "正刊杂志社授权",
          content: "合作期刊均为杂志社授权，抵制假刊、增刊",
          icon: require("../../assets/image/marketing/marketingdui.png"),
        },
        {
          name: "隐私保护",
          content: "可签署保密协议，保障用户隐私，严格管理资料",
          icon: require("../../assets/image/marketing/marketingdui.png"),
        },
      ],
      rightmarketinglist: [
        {
          name: "正规运营",
          content: "所有运营资质正规可查，办公地址详实，专业保障",
          icon: require("../../assets/image/marketing/merketingClose.png"),
        },
        {
          name: "资金监管",
          content: "支付走对公账户，并受第三方银行监管，安全放心",
          icon: require("../../assets/image/marketing/merketingClose.png"),
        },
        {
          name: "正刊杂志社授权",
          content: "合作期刊均为杂志社授权，抵制假刊、增刊",
          icon: require("../../assets/image/marketing/merketingClose.png"),
        },
        {
          name: "隐私保护",
          content: "可签署保密协议，保障用户隐私，严格管理资料",
          icon: require("../../assets/image/marketing/merketingClose.png"),
        },
      ],
      flowmarketinglist: [
        {
          name: "确定期刊",
          icon: require("../../assets/image/marketing/marketing8.jpg"),
        },
        {
          name: "支付定金",
          icon: require("../../assets/image/marketing/marketing9.jpg"),
        },
        {
          name: "录用通知",
          icon: require("../../assets/image/marketing/marketing10.jpg"),
        },
        {
          name: "支付尾款",
          icon: require("../../assets/image/marketing/marketing11.jpg"),
        },
      ],
      articlemarketinglist: [
        {
          name: "晋升必备",
          icon: require("../../assets/image/marketing/marketing4.jpg"),
          content: "多数单位评职级必备,没有发文章将无法获得晋升",
        },
        {
          name: "门槛条件",
          icon: require("../../assets/image/marketing/marketing5.jpg"),
          content: "学术文章是很多评选活动的重大加分项或起始门槛。",
        },
        {
          name: "学术价值",
          icon: require("../../assets/image/marketing/marketing6.jpg"),
          content: " 学术文章能够获得学术声望,有利于个人职业发展",
        },
        {
          name: "良好回报",
          icon: require("../../assets/image/marketing/marketing7.jpg"),
          content: "一次学术文章终生受益，有限的投入，获得满意的回报",
        },
      ],
      card1data: [
        {
          title: "1-3月见录用通知",
          icon: require("../../assets/image/marketing/marketing1.jpg"),
          content: "稳定渠道保证服务速度，节约你的时间",
        },
        {
          title: "版面抢排",
          icon: require("../../assets/image/marketing/marketing2.jpg"),
          content: "期刊版面有限，我们能帮助您顺利获得版面。",
        },
        {
          title: "完成服务后再付款",
          icon: require("../../assets/image/marketing/marketing3.jpg"),
          content: "完善的售后3保障，解决你的后顾之后",
        },
      ],
      catemarketinglist: [
        {
          name: "省级期刊",
          icon: require("../../assets/image/marketing/myicon1.jpg"),
          content: "费用较低，快速审核1~3天提交初审",
        },
        {
          name: "国家级期刊",
          icon: require("../../assets/image/marketing/myicon2.jpg"),
          content: "渠道便捷，绿色通道快速录用发放",
        },
        {
          name: "核心期刊",
          icon: require("../../assets/image/marketing/myicon3.jpg"),
          content: "科技核心，稳定渠道 ",
        },
        {
          name: "普通刊物",
          icon: require("../../assets/image/marketing/myicon4.jpg"),
          content: "见刊快，录用,专业指导",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.now = this.$route.query.id;
      setTimeout(() => {
        var element = document.getElementById(this.$route.query.id);
        console.log(element, this.$route.query.id);
        if (element) {
          element.scrollIntoView();
        }
      }, 200);
    }
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.id) {
        this.now = this.$route.query.id;
        setTimeout(() => {
          var element = document.getElementById(this.$route.query.id);
          console.log(element, this.$route.query.id);
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    },
  },
  mounted() {},
  methods: {
    kefu(){
      window._MICHAT.startChat();
    },
  },
};
</script>

<style scoped>
.card2 .nicemarketinglist {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.card2 .nicemarketinglist .item .name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
}
.card2 .nicemarketinglist .item .content {
  font-size: 12px;
  color: #7c7c7c;
  margin-top: 18px;
}
.card2 .nicemarketinglist .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  cursor: default;
  margin: 0 62px;
}
.card2 .nicemarketinglist .item:hover .index img {
  transform: rotate(360deg);
}
.card2 .nicemarketinglist .item:hover .index .num {
  font-size: 36px;
}

.card2 .nicemarketinglist .item .index {
  width: 90px;
  height: 90px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card2 .nicemarketinglist .item .index img {
  position: absolute;
  width: 100%;
  transition: all 0.3s;
  height: 100%;
}
.card2 .nicemarketinglist .item .index .num {
  color: #fff;
  position: relative;
  font-weight: bold;
  font-size: 28px;
  transition: all 0.3s;
}
.card4 {
  width: 100%;
  position: relative;
  display: flex;
  margin-top: 40px;
  justify-content: center;
}
.card4 .item .marketinglist .marketinglist-item {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.card4 .item .marketinglist .marketinglist-item .icon {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.card4 .item .marketinglist .marketinglist-item .name {
  font-size: 16px;
  font-weight: bold;
}
.card4 .item .marketinglist .marketinglist-item .content {
  font-size: 12px;
  background: none;
  margin-top: 10px;
}
.card4 .left {
  width: 50%;
  background: #00b7ee;
  box-sizing: border-box;
  padding: 66px 0;
  display: flex;
  justify-content: flex-end;
  color: #fff;
}
.card4 .vs {
  width: 60px;
  position: absolute;
  box-sizing: border-box;
  padding-top: 66px;
}
.card4 .vs img {
  width: 60px;
  height: 60px;
  margin-bottom: 80px;
}
.card4 .item {
  width: 80%;
  box-sizing: border-box;
  padding: 0 120px;
}
.card4 .item .title {
  font-size: 30px;
  font-weight: bold;
  line-height: 60px;
}
.card4 .right {
  width: 50%;
  background: #eeeeee;
  box-sizing: border-box;
  padding: 66px 0;
  color: #000;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background: #00b7ee;
  color: #fff;
  font-size: 70px;
  font-weight: bold;
  letter-spacing: 40px;
}
.page-body {
  width: 80%;
  margin-left: 10%;
}
.card1 .item {
  display: flex;
  height: 80px;
  width: 220px;
  cursor: default;
}
.card1 .item:hover .icon {
  animation: card1Animation 0.4s;
}
@keyframes card1Animation {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(0);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0);
  }
}
.card1 .item .icon {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
}
.card1 .item .title {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}
.card1 .item .content {
  font-size: 12px;
  color: #757575;
  line-height: 20px;
}
.card1 {
  width: 100%;
  height: 160px;
  background: #fff;
  border-radius: 26px;
  margin-top: -60px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  padding: 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}
.card2 {
  width: 100%;
  margin-top: 86px;
  box-sizing: border-box;
  padding-bottom: 36px;
}
.card2 .title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}
.card2 .subtitle {
  text-align: center;
  color: #757575;
  font-size: 16px;
  margin-top: 12px;
}
.card2 .catemarketinglist {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 48px;
}
.card2 .catemarketinglist .item {
  display: flex;
}
.card2 .catemarketinglist .item .icon {
  width: 60px;
  height: 60px;
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.1);
  border-radius: 16px;
  margin-bottom: 30px;
  transition: all 0.4s;
}
.card2 .catemarketinglist .item .line {
  width: 90px;
  border-top: dashed 1px #d2d2d2;
  margin: 0 26px;
  margin-top: 30px;
}
.card2 .articlemarketinglist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 62px;
}
.card2 .articlemarketinglist .item {
  width: 23%;
  height: 280px;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 20px;
  cursor: default;
  transition: all 0.4s;
}
.card2 .articlemarketinglist .item:hover .icon {
  transform: rotateY(180deg);
}
.card2 .articlemarketinglist .item:hover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
}
.card2 .articlemarketinglist .item .icon {
  width: 80px;
  height: 80px;
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.1);
  border-radius: 16px;
  margin-bottom: 30px;
  transition: all 0.4s;
}
.card2 .articlemarketinglist .item .name {
  font-size: 22px;
  font-weight: bold;
}
.card2 .articlemarketinglist .item .content {
  font-size: 14px;
  color: #757575;
  margin-top: 24px;
}
.card2 .button {
  width: 300px;
  height: 56px;
  border-radius: 28px;
  margin: 0 auto;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00b7ee;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}
.card2 .button:hover {
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
}
.content {
  background: #fff;
}
.card3 {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 36px;
  background: #f7f7f7;
  box-sizing: border-box;
  padding-top: 60px;
}
.card3 .title {
  width: 100%;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
}
.card3 .subtitle {
  text-align: center;
  color: #757575;
  font-size: 16px;
  margin-top: 12px;
}
.card3 .articlemarketinglist {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 62px;
  width: 80%;
}
.card3 .articlemarketinglist .item {
  width: 23%;
  height: 300px;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0 0 10px rgba(100, 100, 100, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  padding-top: 30px;
  cursor: default;
  transition: all 0.4s;
}
.card3 .articlemarketinglist .item:hover .icon {
  transform: translateY(-20px);
}
.card3 .articlemarketinglist .item:hover {
  box-shadow: 0 0 30px rgba(56, 243, 243, 0.5);
}
.card3 .articlemarketinglist .item .icon {
  width: 100px;
  height: 120px;
  margin-bottom: 30px;
  transition: all 0.4s;
}
.card3 .articlemarketinglist .item .name {
  font-size: 22px;
  font-weight: bold;
}
.card3 .articlemarketinglist .item .content {
  font-size: 14px;
  color: #757575;
  margin-top: 24px;
}

.ptyscontents2 {
  display: flex;
  align-items: center;
  margin-top: 80px;
}

.ptyscontents2 ul {
  flex: 1;
  marketinglist-style: none;
}

.ptyscontents2 ul li {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  margin-bottom: 100px;
  border-radius: 5px;
  color: #000;
}
.ptyscontents2 ul li .icon{
  width: 64px;
  height: 64px;
  margin-right: 26px;
}
.ptyscontents2 ul li .icon img{
  width: 64px;
  height: 64px;
}
.ptyscontents2 ul li .ptyscontents2-item{
  width: 160px;
}
.ptyscontents2 ul li .ptyscontents2-item .name{
  font-size: 18px;
  font-weight: bold;
}
.ptyscontents2 ul li .ptyscontents2-item .conent{
  font-size: 12px;
  color: #7c7c7c;
  margin-top: 16px;
}
.baozhang2{
  margin: 0 60px;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>
